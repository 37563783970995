import Seo from 'components/seo'
import Layout from 'components/layout'
import Navbar from 'components/navbar'
import Cta from 'components/cta'
import Footer from 'components/footer'

export default function fourofour() {
  return (
    <>
      <Seo title="404 - cette page n'existe pas" canonical="/404">
        <meta name="robots" content="noindex" />
      </Seo>
      <div className="min-h-screen overflow-hidden">
        <Navbar />
        <section className="mt-24 md:mt-48">
          <Layout className="relative">
            <h1 className="text-center font-serif text-7xl font-bold">404</h1>
            <h2 className="mt-4 text-center text-xl">Cette page n'existe pas !</h2>
            <Cta href="/" className="mt-16" type="sm">
              Revenir à l'accueil
            </Cta>
          </Layout>
        </section>
      </div>
      <div className="mt-40">
        <Footer />
      </div>
    </>
  )
}
